import axios from 'axios'

const JSON_HEADERS = {
  'X-Requested-With': 'XMLHttpRequest',
  'Content-Type': 'application/json',
}
let csrfToken

$(() => {
  csrfToken = document.querySelector("meta[name='csrf-token']").getAttribute('content')
})

function request(url, method, data = {}) {
  return axios({
    method,
    url,
    headers: { ...JSON_HEADERS, 'X-CSRF-Token': csrfToken },
    data,
  })
}

export const get = (url, data) => (
  axios.get(url, {
    params: data,
    headers: JSON_HEADERS,
  })
)

export const post = (url, data) => request(url, 'post', data)

export const put = (url, data) => request(url, 'put', data)

export const patch = (url, data) => request(url, 'patch', data)
//
// export const destroy = (url) => request(url, 'delete');
