const trackLearningObjectReferralPage = (path) => {
  localStorage.setItem('learningObjectReferralPath', path)
}

const getReferralPage = () => localStorage.getItem('learningObjectReferralPath')

export {
  getReferralPage,
  trackLearningObjectReferralPage,
}
