/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable jsx-a11y/heading-has-content */
/* eslint-disable react/jsx-no-target-blank */

import React from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'

export const LinkRenderer = function (props) {
  return (
    <a
      rel="noopener"
      target="_blank"
      {...props}
    />
  )
}

export const HeadingRenderer = function ({ className, properties, idName }) {
  return (
    <h4
      className={className}
      {...properties}
      id={idName || ''}
    />
  )
}

HeadingRenderer.propTypes = {
  className: PropTypes.string,
  properties: PropTypes.object,
  idName: PropTypes.string,
}

/* Note:
  In the FAQ Page Admin, we use the # / h1 markdown as a way of
  identifying (sub)headers and the CTA Buttons, but for accessibility
  reasons we need to convert those h1s to h2s, for page heirarchy.
  We also need to remove the CTA buttons from being inside of heading
  elements, since they are buttons, not section headings.
  So this code is used to convert those two elements appropriately,
  along with the pre-existing render-links-in-h1s-as-CTA mixin.
*/
export const SubheadAndCTARenderer = function (props) {
  return _.map(props.node.children, (item, index) => {
    if (item.tagName === 'a') {
      return (
        <div
          key={index}
          className="cta-button"
          {...props}
        />
      )
    }
    return (
      <h2
        key={index}
        {...props}
      />
    )
  })
}
