import React from 'react'
import PropTypes from 'prop-types'

import { capitalize, snakeCase } from 'lodash'

const RailsStyleInput = function ({
  fieldFor,
  fieldName,
  type,
  value,
  onChange,
  errors,
  placeholder,
  children,
  pattern,
  autoFocus, // Use very sparingly to not confuse screenreaders
}) {
  const snakeCaseName = snakeCase(fieldName)
  /* eslint-disable jsx-a11y/no-autofocus */
  return (
    <div className="form__input-container form__input-container--condensed">
      {children}
      <input
        type={type}
        pattern={pattern}
        placeholder={capitalize(placeholder || fieldName)}
        className={
          `form__input-field form__input-field--condensed ${errors && 'form__input-field--error'}`
        }
        name={`${fieldFor}[${snakeCaseName}]`}
        value={value}
        onChange={onChange}
        id={`${fieldFor}_${snakeCaseName}`}
        autoFocus={autoFocus}
      />
      { errors && (
      <div className="form__field-error">
        { errors[0] }
      </div>
      )}
    </div>
  )
  /* eslint-enable jsx-a11y/no-autofocus */
}

RailsStyleInput.propTypes = {
  fieldFor: PropTypes.string.isRequired,
  fieldName: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  value: PropTypes.string,
  onChange: PropTypes.func,
  errors: PropTypes.array,
  placeholder: PropTypes.string,
  children: PropTypes.object,
  pattern: PropTypes.string,
  autoFocus: PropTypes.bool,
}

export default RailsStyleInput
