export const NUM_OF_THEMES = 8
export function findTheme(index) { return index % NUM_OF_THEMES }

export const NO_OF_LO_IMAGES = 9

export const OBJECT_TYPES = {
  careerCollection: 'CareerCollection',
  communityCollection: 'CommunityCollection',
  learningObject: 'LearningObject',
}

export const USER_STATUSES = {
  loggedOut: 'logged out',
  verified: 'verified',
  expired: 'expired',
  temporary: 'temporary',
  demo: 'demo',
}

export const LOADING_STATUSES = {
  notLoaded: 'not loaded',
  loading: 'loading',
  loaded: 'loaded',
}

export const FLASH_NOTIFICATIONS = {
  loginForPaidResource: 'login_for_paid_resource',
  libraryCardRequiredForPaidResource: 'library_card_required_for_paid_resource',
}

export const HOMEPAGE_DISPLAY_TYPES = {
  collections: 'collections',
  story: 'story',
}

export const SEARCH_FIRST_PAGE = 0
export const LEARNING_OBJECTS_FIRST_PAGE = 1
