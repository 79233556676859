import React from 'react'
import PropTypes from 'prop-types'
import ReactMarkdown from 'react-markdown'

const Header = function ({ header, subheader, big }) {
  return (
    <div className="section-header">
      <div className="section-header__head">
        {big ? (
          <h1 className="heading-1"><ReactMarkdown children={header} /></h1>
        ) : (
          <h2 className="heading-2"><ReactMarkdown children={header} /></h2>
        )}
      </div>
      <div className="section-header__subhead">
        <ReactMarkdown children={subheader} />
      </div>
    </div>
  )
}

Header.propTypes = {
  header: PropTypes.string.isRequired,
  subheader: PropTypes.string.isRequired,
  big: PropTypes.bool,
}

export default Header
