import React from 'react'
import PropTypes from 'prop-types'

import { snakeCase } from 'lodash'

/* eslint-disable jsx-a11y/label-has-associated-control */
const RailsStyleCheckbox = function ({
  label, fieldFor, fieldName, value, onChange, className,
}) {
  const snakeCaseName = snakeCase(fieldName)
  return (
    <div
      className={`form__input-container form__input-container--condensed ${className}`}
    >
      <div className="form__inline-label form__inline-label--checkbox">{label}</div>
      <label className="custom-checkbox form__checkbox">
        <input
          type="hidden"
          value={value ? 1 : 0}
          name={`${fieldFor}[${snakeCaseName}]`}
        />
        <input
          type="checkbox"
          checked={value}
          onChange={onChange}
          name={`${fieldFor}[${snakeCaseName}]`}
          id={`${fieldFor}_${snakeCaseName}`}
        />
        <div className="custom-checkbox__icon" />
      </label>
    </div>
  )
}
/* eslint-enable jsx-a11y/label-has-associated-control */

RailsStyleCheckbox.propTypes = {
  label: PropTypes.string.isRequired,
  fieldFor: PropTypes.string.isRequired,
  fieldName: PropTypes.string.isRequired,
  value: PropTypes.bool,
  onChange: PropTypes.func,
  className: PropTypes.string,
}

export default RailsStyleCheckbox
