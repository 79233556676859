import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import { isEmpty } from 'lodash'

const ButtonInterior = function ({ content, icon }) {
  return (
    <>
      <span className="button__text">
        { content }
      </span>
      { icon && (
        <span className={`button__icon button__icon--${icon}`} />
      )}
    </>
  )
}

ButtonInterior.propTypes = {
  content: PropTypes.string,
  icon: PropTypes.string,
}

const Button = function ({
  url, icon, linkTarget, variant, classes, content, onClick,
}) {
  const buttonClasses = classnames(
    `button button--${variant || 'solid-gold'}`,
    { [classes]: !isEmpty(classes) },
  )
  if (url) {
    return (
      <a
        href={url}
        className={buttonClasses}
        target={linkTarget || '_blank'}
      >
        <ButtonInterior content={content} icon={icon} />
      </a>
    )
  }
  return (
    <button
      type="button"
      className={buttonClasses}
      onClick={onClick}
    >
      <ButtonInterior content={content} icon={icon} />
    </button>
  )
}

Button.propTypes = {
  url: PropTypes.string,
  icon: PropTypes.string,
  onClick: PropTypes.func,
  linkTarget: PropTypes.string,
  variant: PropTypes.string,
  classes: PropTypes.string,
  content: PropTypes.string,
}

export default Button
