const transformToAssocArray = (prmstr) => {
  const params = {}
  const prmarr = prmstr.split('&')
  for (let i = 0; i < prmarr.length; i++) {
    const tmparr = prmarr[i].split('=')
    params[tmparr[0]] = tmparr[1] // eslint-disable-line prefer-destructuring
  }
  return params
}

const getUrlParams = () => {
  const prmstr = window.location.search.substr(1)
  return prmstr != null && prmstr !== '' ? transformToAssocArray(prmstr) : {}
}

export {
  getUrlParams,
}
