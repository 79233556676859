export const {
  ALGOLIA_INSTANCE_FACET,
} = process.env

export const {
  CONTENT_PER_PAGE,
} = process.env

export const {
  INSTANCE_LOGO_URL,
} = process.env

export const {
  INSTANCE_TEXT_NAME,
} = process.env

export const {
  INSTANCE_WELCOME_VIDEO_URL,
} = process.env

export const {
  CHECK_PIN,
} = process.env

export const {
  HIDE_CAREER_COLLECTION_TITLE,
} = process.env

export const {
  GOOGLE_ANALYTICS_TRACKING_ID,
} = process.env

export const {
  APP_LIVE_DATE,
} = process.env

export const {
  ALGOLIA_OBJECT_TYPES_FACET,
} = process.env

export const {
  TRACK_ANALYTICS,
} = process.env

export const {
  JRR_DISPLAYED,
} = process.env

export const {
  JRR_URL,
} = process.env

// Note: Export each const individually or it will break
