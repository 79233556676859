import React from 'react'
import classnames from 'classnames'
import PropTypes from 'prop-types'
import { isEmpty } from 'lodash'

import { favoriteLink } from './helpers'
import { OBJECT_TYPES } from './constants'

/* eslint-disable jsx-a11y/no-noninteractive-tabindex */
// The above disable is because interaction for tabIndex is
// controlled by js that isn't part of this component see toggle.js
const Favorite = function ({
  favorite, object, objectType, loggedIn, asIcon = true, regularCardSize = true,
}) {
  return (
    <div className={
      classnames(
        {
          'collection-card__favorite-container': objectType.match('Collection'),
          'learning-object-card__favorite-container': objectType.match(OBJECT_TYPES.learningObject),
        },
      )
    }
    >
      <div
        tabIndex="0"
        aria-pressed={!isEmpty(favorite)}
        aria-label="Favorite Button"
        className={
        classnames(
          'favorite',
          {
            'favorite--selected': favorite && loggedIn,
            'js-toggle-element': loggedIn,
            'js-trigger-flash-notification': !loggedIn,
          },
        )
      }
        data-ajax-path={loggedIn && favoriteLink(favorite, object, objectType)}
        data-flash-notification={!loggedIn && 'login_to_favorite'}
      >
        {
          asIcon && (
            <div className={`favorite__icon ${regularCardSize ? '' : 'favorite__icon--red'}`} />
          )
        }
        {
          !asIcon && (
            <div className="button button--hollow-gold">
              <span className="favorite__button-text" />
            </div>
          )
        }

      </div>
    </div>
  )
}
/* eslint-enable jsx-a11y/no-noninteractive-tabindex */

Favorite.propTypes = {
  favorite: PropTypes.object,
  object: PropTypes.object,
  objectType: PropTypes.string,
  loggedIn: PropTypes.bool,
  asIcon: PropTypes.bool,
  regularCardSize: PropTypes.bool,
}

export default Favorite
