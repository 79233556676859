import { useState, useEffect } from 'react'
import { useLocation } from 'react-router-dom'

// const shouldLog = false;

export function useQuery() {
  return new URLSearchParams(useLocation().search)
}

export function usePageTitle(title) {
  useEffect(() => {
    document.title = `Bendable: ${title}`
  }, [title])
}

export function useSessionStorage(key, initialValue) {
  // State to store our value
  // Pass initial state function to useState so logic is only executed once
  const [storedValue, setStoredValue] = useState(() => {
    if (typeof window === 'undefined') {
      // if (shouldLog) console.log('useSessionStorage() no window; returning initialValue: ', initialValue);
      return initialValue
    }
    try {
      // Get from local storage by key
      const item = window.sessionStorage.getItem(key)
      // Parse stored json or if none return initialValue
      // if (shouldLog) console.log('useSessionStorage() item: ', item);
      // if (shouldLog) console.log('useSessionStorage() initialValue: ', initialValue);
      return item ? JSON.parse(item) : initialValue
    } catch (error) {
      // If error also return initialValue
      // console.log(error);
      return initialValue
    }
  })
  // Return a wrapped version of useState's setter function that ...
  // ... persists the new value to sessionStorage.
  const setValue = (value) => {
    try {
      // Allow value to be a function so we have same API as useState
      const valueToStore = value instanceof Function ? value(storedValue) : value
      // Save state
      setStoredValue(valueToStore)
      // Save to local storage
      if (typeof window !== 'undefined') {
        // if (shouldLog) console.log('useSessionStorage() setting key, valueToStore: ', key, valueToStore);
        window.sessionStorage.setItem(key, JSON.stringify(valueToStore))
      }
    } catch (error) {
      // A more advanced implementation would handle the error case
      // console.log(error);
    }
  }
  return [storedValue, setValue]
}
