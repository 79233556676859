import { get, post, patch } from './requests'

export const apiRoutes = {
  favorites: {
    index: () => '/api/favorites',
    delete: (id) => `/api/favorites/${id}?_method=delete`,
    create: (id, contentType) => `/api/favorites?content_id=${id}&content_type=${contentType}`,
  },
  favoriteResources: {
    index: () => '/api/favorite_resources',
  },
  careerCollections: {
    show: (id) => `/api/career_collections/${id}`,
  },
  categories: {
    index: () => '/api/categories',
    learningObjects: {
      index: (id) => `/api/categories/${id}/learning_objects`,
    },
    show: (id) => `/api/categories/${id}`,
  },
  boards: {
    index: () => '/api/boards',
    show: (id) => `/api/boards/${id}`,
  },
  exploreSections: {
    index: () => '/api/explore_sections',
  },
  faqSections: {
    index: () => '/api/faq_sections',
  },
  homepage: {
    collectionGroup: {
      show: () => '/api/homepage/collection_group',
    },
    featuredLibraries: {
      show: () => '/api/homepage/featured_library',
    },
    featuredTopics: {
      show: () => '/api/homepage/featured_topic_group',
    },
    featuredBoardGroups: {
      show: () => '/api/homepage/featured_board_group',
    },
    heroCarousel: {
      show: () => '/api/homepage/hero_carousel',
    },
    homepageSections: {
      index: () => '/api/homepage/homepage_sections',
    },
    spotlights: {
      index: () => '/api/homepage/spotlights',
    },
  },
  tags: {
    index: () => '/api/tags',
  },
  learningObjects: {
    show: (id) => `/api/learning_objects/${id}`,
    learningObjectState: {
      create: (learningObjectid) => `/api/learning_objects/${learningObjectid}/learning_object_state`,
      update: (learningObjectid) => `/api/learning_objects/${learningObjectid}/learning_object_state`,
    },
  },
}

const api = {
  favorites: {
    index: () => get(apiRoutes.favorites.index()),
  },
  favoriteResources: {
    index: () => get(apiRoutes.favoriteResources.index()),
  },
  careerCollections: {
    show: (id) => get(apiRoutes.careerCollections.show(id)),
  },
  categories: {
    index: () => get(apiRoutes.categories.index()),
    learningObjects: {
      index: (id, page) => get(apiRoutes.categories.learningObjects.index(id), { page }),
    },
    show: (id) => get(apiRoutes.categories.show(id)),
  },
  boards: {
    index: () => get(apiRoutes.boards.index()),
    show: (id) => get(apiRoutes.boards.show(id)),
  },
  exploreSections: {
    index: () => get(apiRoutes.exploreSections.index()),
  },
  faqSections: {
    index: () => get(apiRoutes.faqSections.index()),
  },
  homepage: {
    collectionGroup: {
      show: () => get(apiRoutes.homepage.collectionGroup.show()),
    },
    featuredLibraries: {
      show: () => get(apiRoutes.homepage.featuredLibraries.show()),
    },
    featuredTopics: {
      show: () => get(apiRoutes.homepage.featuredTopics.show()),
    },
    featuredBoardGroups: {
      show: () => get(apiRoutes.homepage.featuredBoardGroups.show()),
    },
    heroCarousel: {
      show: () => get(apiRoutes.homepage.heroCarousel.show()),
    },
    homepageSections: {
      index: () => get(apiRoutes.homepage.homepageSections.index()),
    },
    spotlights: {
      index: () => get(apiRoutes.homepage.spotlights.index()),
    },
  },
  learningObjects: {
    show: (id) => get(apiRoutes.learningObjects.show(id)),
    learningObjectState: {
      create: (learningObjectid, data) => post(
        apiRoutes.learningObjects.learningObjectState.create(
          learningObjectid,
        ),
        data,
      ),
      update: (learningObjectid, data) => patch(
        apiRoutes.learningObjects.learningObjectState.update(
          learningObjectid,
        ),
        data,
      ),
    },
  },
  tags: {
    index: () => get(apiRoutes.tags.index()),
  },
}

export default api
