import { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'

const ScrollToTop = function () {
  const { pathname, key } = useLocation()
  const [pathKeys, setPathKeys] = useState({})

  useEffect(() => {
    if (!pathKeys[key]) {
      const newPathKeys = { ...pathKeys, [key]: true }
      setPathKeys(newPathKeys)
      window.scrollTo(0, 0)
    }
  }, [pathname])

  return null
}

export default ScrollToTop
