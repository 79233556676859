import { forEach, isObject } from 'lodash';

import { apiRoutes } from '../../services/api';

export const selectFavorite = (object, objectType) => (state) => state.app.favorites.items.find(
  (favorite) => favorite.content_type === objectType
         && favorite.content_id === object.id,
)

export const favoriteLink = (favorite, object, objectType) => {
  if (favorite) {
    return apiRoutes.favorites.delete(favorite.id)
  }
  return apiRoutes.favorites.create(object.id, objectType)
}

/* eslint-disable import/no-mutable-exports */
export let csrfToken
/* eslint-enable import/no-mutable-exports */

$(() => {
  csrfToken = document.querySelector('[name=csrf-token]').content
})

const camelToSnakeCase = (str) => str.replace(/[A-Z]/g, (letter) => `_${letter.toLowerCase()}`)

export const objCamelToSnakeCase = (obj) => {
  const snakeCaseData = {}

  forEach(obj, (v, k) => {
    snakeCaseData[camelToSnakeCase(k)] = v
  })

  return snakeCaseData
}

/**
 * Build a facet argument suitable for use with algoliasearch
 *
 * @param {*} searchFacets value from store
 */
export const buildAlgoliaFacetArg = (searchFacets) => {
  const facetArg = []
  if (!isObject(searchFacets)) return facetArg
  const facets = Object.keys(searchFacets)
  facets.forEach((key) => {
    if (searchFacets[key] && (searchFacets[key].length === 1)) {
      const data = searchFacets[key][0]
      facetArg.push(`${key}:${data.value}`)
    }
    if (searchFacets[key] && (searchFacets[key].length > 1)) {
      const logicalOrArray = []
      searchFacets[key].forEach((data) => {
        logicalOrArray.push(`${key}:${data.value}`)
      })
      facetArg.push(logicalOrArray)
    }
  })
  return facetArg
}

export const getCurrentMuiBreakpoint = () => {
  if (window.matchMedia('(min-width:1536px)').matches) return 'xl';
  if (window.matchMedia('(min-width:1200px)').matches) return 'lg';
  if (window.matchMedia('(min-width:900px)').matches) return 'md';
  if (window.matchMedia('(min-width:600px)').matches) return 'sm';
  return 'xs';
};
